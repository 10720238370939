import * as React from "react";

import { A, Button, Container, GiveMeSomeSpace, P } from "@util/standard";
import { IconButton, SocialShare } from "@global";

import Modal from "react-modal";
import ReactDOM from "react-dom";
import { SanityRecipe } from "@graphql-types";
import { colors } from "@util/constants";
import { navigate } from "gatsby-link";
import { useState } from "react";
import { useStore } from "@state/store";

interface Props {
  recipe: SanityRecipe;
}

const RecipeSocialShare = (props: Props) => {
  const { recipe } = props;
  const [visible, setVisible] = useState(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const handleSocialShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: recipe.info?.title,
          text: "Check out this recipe at Anathoth Farm",
          url:
            "https://www.anathoth.co.nz/recipe/" + recipe.info?.slug?.current,
        })
        .then(() => console.log("Successful share"))
        .catch(error => console.log("Error sharing", error));
    } else {
      setVisible(true);
    }
  };

  const closeModal = () => {
    setVisible(false);
  };

  const handlePrint = () => {
    useStore.setState({
      printableRecipe: {
        recipe: recipe,
      },
    });
    navigate("/print");
    // if (typeof window != "undefined") {
    //   window.print();
    // }
  };

  return (
    <Container
      width="100%"
      display="flex"
      flexDirection="row"
      margin="20px auto"
    >
      <Button
        color="merlin"
        hoverColour="white"
        border={`1.5px solid ${colors.merlin}`}
        height="48px"
        backgroundColour="white"
        borderRadius="36px"
        onClick={handlePrint}
      >
        Print
      </Button>
      <GiveMeSomeSpace />
      {/* <Button
        color="merlin"
        hoverColour="white"
        border={`1.5px solid ${colors.merlin}`}
        height="48px"
        backgroundColour="white"
        borderRadius="36px"
        onClick={handleSocialShare}
      >
        Share
      </Button> */}

      <Modal style={customStyles} isOpen={visible} onRequestClose={closeModal}>
        <Container flexDirection="column">
          <h3>Share</h3>
          <SocialShare
            title={recipe.info?.title}
            url={`https://www.anathoth.co.nz/recipe/${recipe.info?.slug?.current}`}
            image={recipe.info?.featuredMedia?.image?.asset?.url}
          />
        </Container>
      </Modal>
    </Container>
  );
};

export default RecipeSocialShare;
